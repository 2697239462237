// src/app/pages/dashboard/Dashboard.js
import React from 'react';
import StockByShip from './StockByShip';
import DeliveriesByDate from './DeliveriesByDate';
import ClientsByDate from './ClientsByDate';
import MonthlyFuelDeliveries from './MonthlyFuelDeliveries';

const Dashboard = () => {
  return (
    <div>
      <h1>Gráficos</h1>
      <StockByShip />
      <DeliveriesByDate />
      <ClientsByDate />
      <MonthlyFuelDeliveries />
    </div>
  );
};

export default Dashboard;
