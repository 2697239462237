import React, { useEffect, useState } from 'react';
import dashboardServices from '../../services/dashboard-services';
import { makeStyles } from '@mui/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  OutlinedInput
} from '@mui/material';

const useStyles = makeStyles(() => ({
  container: {
    borderRadius: '10px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    margin: '20px 0',
    padding: '20px',
  },
  table: {
    minWidth: 650,
    borderRadius: '10px',
  },
  headerCell: {
    fontWeight: 'bold',
    color: '#4B5563',
    fontSize: '16px',
    backgroundColor: '#F3F4F6',
    borderBottom: '2px solid #E5E7EB',
  },
  bodyCell: {
    color: '#1F2937',
    fontSize: '14px',
    borderBottom: '1px solid #E5E7EB',
  },
  row: {
    '&:hover': {
      backgroundColor: '#F9FAFB',
    },
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'space-between', // Alineación similar a DeliveriesByDate
    marginBottom: '20px',
    flexWrap: 'wrap', // Permite que los filtros se ajusten en pantallas pequeñas
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// Función para obtener el primer día del mes actual
const getFirstDayOfMonth = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), 1).toISOString().split('T')[0];
};

// Función para obtener el día actual
const getToday = () => {
  const today = new Date();
  return today.toISOString().split('T')[0];
};

// Función para agregar un día a una fecha en formato YYYY-MM-DD
const addOneDay = (dateString) => {
  const date = new Date(dateString);
  date.setDate(date.getDate() + 1);
  // Convertir de nuevo a formato YYYY-MM-DD
  return date.toISOString().split('T')[0];
};

const ClientsByDate = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    cliente: [],
    naviera: [],
    embarcacion: [],
    desde: getFirstDayOfMonth(), // Primer día del mes
    hasta: getToday()            // Día actual
  });

  // Opciones de filtros cargadas una sola vez
  const [clientes, setClientes] = useState([]);
  const [navieras, setNavieras] = useState([]);
  const [embarcaciones, setEmbarcaciones] = useState([]);

  // Cargar las opciones de los filtros solo al montar el componente
  useEffect(() => {
    fetchInitialData();
  }, []);

  // Función para obtener los datos iniciales de filtros
  const fetchInitialData = () => {
    setLoading(true);
    dashboardServices.getDeliveriesByCustomer({})
      .then(response => {
        const uniqueClientes = [...new Set(response.map(client => client.customer))].sort((a, b) => a.localeCompare(b));

        const allNavieras = response.flatMap(client => client.navieras);
        const uniqueNavieras = [...new Set(allNavieras)].filter(Boolean).sort((a, b) => a.localeCompare(b));

        const allEmbarcaciones = response.flatMap(client => client.embarcaciones);
        const uniqueEmbarcaciones = [...new Set(allEmbarcaciones)].filter(Boolean).sort((a, b) => a.localeCompare(b));

        // Inicializar las opciones de los filtros una sola vez
        setClientes(uniqueClientes);
        setNavieras(uniqueNavieras);
        setEmbarcaciones(uniqueEmbarcaciones);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching filter options:', error);
        setLoading(false);
      });
  };

  // Actualizamos los datos cada vez que cambian los filtros
  useEffect(() => {
    fetchFilteredData();
  }, [filters.desde, filters.hasta, filters.cliente, filters.naviera, filters.embarcacion]);

  // Función para obtener los datos filtrados
  const fetchFilteredData = () => {
    setLoading(true);

    // Crear una copia de los filtros actuales
    const adjustedFilters = { ...filters };

    // Si hay una fecha 'hasta' seleccionada, agregarle un día
    if (adjustedFilters.hasta) {
      adjustedFilters.hasta = addOneDay(adjustedFilters.hasta);
    }

    // Llamar al servicio con los filtros ajustados
    dashboardServices.getDeliveriesByCustomer(adjustedFilters)
      .then(response => {
        const mappedData = response.map(client => ({
          customer: client.customer,
          totalLiters: client.total_liters,
          lastDeliveryDate: client.last_delivery_date ? new Date(client.last_delivery_date).toLocaleDateString('es-ES') : '',
        }));
        setData(mappedData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  };

  // Función auxiliar para obtener las opciones según el tipo de filtro
  const getOptions = (type) => {
    switch(type) {
      case 'cliente':
        return clientes;
      case 'naviera':
        return navieras;
      case 'embarcacion':
        return embarcaciones;
      default:
        return [];
    }
  };

  // Función para manejar "Seleccionar Todo"
  const handleSelectAll = (type) => {
    if (filters[type].length === getOptions(type).length) {
      // Si todo está seleccionado, deselecciona todo
      setFilters({
        ...filters,
        [type]: [],
      });
    } else {
      // Si no, selecciona todos
      setFilters({
        ...filters,
        [type]: getOptions(type),
      });
    }
  };

  // Manejar el cambio de los filtros (checkboxes)
  const handleFilterChange = (event, type) => {
    const { value } = event.target;

    if (value.includes('select-all')) {
      handleSelectAll(type);
      return;
    }

    setFilters({
      ...filters,
      [type]: typeof value === 'string' ? value.split(',') : value,
    });
  };

  return (
    <Paper className={classes.container}>
      <div className={classes.filterContainer}>
        {/* Filtro Naviera */}
        <FormControl variant="outlined" size="small" style={{ marginRight: '10px', width: '200px', marginBottom: '10px' }}>
          <InputLabel>Naviera</InputLabel>
          <Select
            multiple
            value={filters.naviera}
            onChange={(e) => handleFilterChange(e, 'naviera')}
            input={<OutlinedInput label="Naviera" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {/* Opción Seleccionar Todo */}
            <MenuItem key="select-all-naviera" value="select-all" onClick={() => handleSelectAll('naviera')}>
              <Checkbox checked={filters.naviera.length === navieras.length && navieras.length > 0} />
              <ListItemText primary="Seleccionar Todo" />
            </MenuItem>
            {navieras.map((naviera, index) => (
              <MenuItem key={index} value={naviera}>
                <Checkbox checked={filters.naviera.indexOf(naviera) > -1} />
                <ListItemText primary={naviera} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Filtro Embarcación */}
        <FormControl variant="outlined" size="small" style={{ marginRight: '10px', width: '200px', marginBottom: '10px' }}>
          <InputLabel>Embarcación</InputLabel>
          <Select
            multiple
            value={filters.embarcacion}
            onChange={(e) => handleFilterChange(e, 'embarcacion')}
            input={<OutlinedInput label="Embarcación" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {/* Opción Seleccionar Todo */}
            <MenuItem key="select-all-embarcacion" value="select-all" onClick={() => handleSelectAll('embarcacion')}>
              <Checkbox checked={filters.embarcacion.length === embarcaciones.length && embarcaciones.length > 0} />
              <ListItemText primary="Seleccionar Todo" />
            </MenuItem>
            {embarcaciones.map((embarcacion, index) => (
              <MenuItem key={index} value={embarcacion}>
                <Checkbox checked={filters.embarcacion.indexOf(embarcacion) > -1} />
                <ListItemText primary={embarcacion} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Filtro Cliente */}
        <FormControl variant="outlined" size="small" style={{ marginRight: '10px', width: '200px', marginBottom: '10px' }}>
          <InputLabel>Cliente</InputLabel>
          <Select
            multiple
            value={filters.cliente}
            onChange={(e) => handleFilterChange(e, 'cliente')}
            input={<OutlinedInput label="Cliente" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {/* Opción Seleccionar Todo */}
            <MenuItem key="select-all-cliente" value="select-all" onClick={() => handleSelectAll('cliente')}>
              <Checkbox checked={filters.cliente.length === clientes.length && clientes.length > 0} />
              <ListItemText primary="Seleccionar Todo" />
            </MenuItem>
            {clientes.map((cliente, index) => (
              <MenuItem key={index} value={cliente}>
                <Checkbox checked={filters.cliente.indexOf(cliente) > -1} />
                <ListItemText primary={cliente} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Filtro Fecha Desde */}
        <TextField
          label="Desde"
          name="desde"
          type="date"
          value={filters.desde}
          onChange={(e) => setFilters({ ...filters, desde: e.target.value })}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          size="small"
          style={{ marginRight: '10px', marginBottom: '10px', width: '150px' }}
        />

        {/* Filtro Fecha Hasta */}
        <TextField
          label="Hasta"
          name="hasta"
          type="date"
          value={filters.hasta}
          onChange={(e) => setFilters({ ...filters, hasta: e.target.value })}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          size="small"
          style={{ marginBottom: '10px', width: '150px' }}
        />
      </div>

      <Typography variant="h6" gutterBottom>
        Entrega por cliente
      </Typography>

      {loading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        <Table className={classes.table} aria-label="tabla de clientes">
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>#</TableCell> {/* Nueva Celda para Índice */}
              <TableCell className={classes.headerCell}>Cliente</TableCell>
              <TableCell align="right" className={classes.headerCell}>Litros entregados (L)</TableCell>
              <TableCell align="right" className={classes.headerCell}>Última Fecha de Entrega</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index} className={classes.row}>
                <TableCell component="th" scope="row" className={classes.bodyCell}>
                  {index + 1} {/* Mostrar el Índice */}
                </TableCell>
                <TableCell className={classes.bodyCell}>{row.customer}</TableCell>
                <TableCell align="right" className={classes.bodyCell}>{row.totalLiters.toLocaleString()}</TableCell>
                <TableCell align="right" className={classes.bodyCell}>{row.lastDeliveryDate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Paper>
  );
};

export default ClientsByDate;
