import React, { useEffect, useState } from 'react';
import dashboardServices from '../../services/dashboard-services';
import { makeStyles } from '@mui/styles';
import { 
  Paper, 
  Typography, 
  CircularProgress, 
  FormControl, 
  InputLabel, 
  Select, 
  Checkbox, 
  ListItemText, 
  OutlinedInput, 
  MenuItem 
} from '@mui/material';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer 
} from 'recharts';

const useStyles = makeStyles(() => ({
  container: {
    borderRadius: '10px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    margin: '20px 0',
    padding: '20px',
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'space-between', 
    alignItems: 'center',
    marginBottom: '20px',
    flexWrap: 'wrap',
  },
  filterItem: {
    flex: '1',
    marginRight: '10px', 
    maxWidth: '200px', 
    minWidth: '150px',
    marginBottom: '10px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Objeto de traducción de meses
const monthTranslations = {
  "January": "Enero",
  "February": "Febrero",
  "March": "Marzo",
  "April": "Abril",
  "May": "Mayo",
  "June": "Junio",
  "July": "Julio",
  "August": "Agosto",
  "September": "Septiembre",
  "October": "Octubre",
  "November": "Noviembre",
  "December": "Diciembre",
};

// Función para traducir "September 2024" a "Septiembre 2024"
const translateYearMonth = (yearMonth) => {
  const [month, year] = yearMonth.split(' ');
  const translatedMonth = monthTranslations[month] || month;
  return `${translatedMonth} ${year}`;
};

const MonthlyFuelDeliveries = () => {
  const classes = useStyles();
  const [allData, setAllData] = useState([]); // Almacena todos los datos recibidos
  const [data, setData] = useState([]); // Datos filtrados a mostrar
  const [loading, setLoading] = useState(true);

  // Variables para almacenar las opciones de los filtros
  const [navieras, setNavieras] = useState([]);
  const [embarcaciones, setEmbarcaciones] = useState([]);
  const [clientes, setClientes] = useState([]);

  // Estado de los filtros, incluyendo el estado de "Mostrar todo"
  const [filters, setFilters] = useState({
    naviera: [],
    embarcacion: [],
    cliente: [],
    showAllNaviera: true,
    showAllEmbarcacion: true,
    showAllCliente: true,
  });

  // Cargar las opciones de los filtros y todos los datos al montar el componente
  useEffect(() => {
    loadInitialData();
  }, []);

  // Función para cargar las opciones de los filtros y todos los datos
  const loadInitialData = () => {
    setLoading(true);
    dashboardServices.getMonthlyFuelDeliveries({})
      .then(response => {
        // Guardamos todos los datos
        setAllData(response);

        // Extraemos opciones únicas para los filtros y las ordenamos alfabéticamente
        const uniqueNavieras = [...new Set(response.map(item => item.naviera))].filter(Boolean).sort((a, b) => a.localeCompare(b));
        const uniqueEmbarcaciones = [...new Set(response.map(item => item.embarcacion))].filter(Boolean).sort((a, b) => a.localeCompare(b));
        const uniqueClientes = [...new Set(response.map(item => item.customer))].filter(Boolean).sort((a, b) => a.localeCompare(b));

        // Actualizamos las opciones de los filtros
        setNavieras(uniqueNavieras);
        setEmbarcaciones(uniqueEmbarcaciones);
        setClientes(uniqueClientes);

        // Procesamos y establecemos los datos iniciales
        processData(response);
      })
      .catch(error => {
        console.error('Error fetching initial data:', error);
        setLoading(false);
      });
  };

  // Aplicar los filtros cada vez que cambian
  useEffect(() => {
    if (!loading) {
      processData(allData);
    }
  }, [filters]);

  // Función para procesar y establecer los datos
  const processData = (responseData) => {
    // Filtrar los datos según los filtros seleccionados
    const filteredData = responseData.filter(item => {
      const navieraMatch = filters.showAllNaviera || (item.naviera && filters.naviera.includes(item.naviera));
      const embarcacionMatch = filters.showAllEmbarcacion || (item.embarcacion && filters.embarcacion.includes(item.embarcacion));
      const clienteMatch = filters.showAllCliente || (item.customer && filters.cliente.includes(item.customer));
      return navieraMatch && embarcacionMatch && clienteMatch;
    });

    // Agrupar datos por año y mes, sumando los litros
    const groupedData = filteredData.reduce((acc, current) => {
      const key = `${current.year}-${current.month}`;
      const existing = acc.find(item => item.year === current.year && item.month === current.month);
      if (existing) {
        existing.liters += current.total_liters;
      } else {
        acc.push({ year: current.year, month: current.month, liters: current.total_liters });
      }
      return acc;
    }, []);

    // Generar lista de los últimos 13 meses desde el mismo mes del año pasado hasta el mes actual
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 12, 1); // Hace 13 meses
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1); // Mes actual

    const months = [];
    const date = new Date(startDate);
    while (date <= endDate) {
      const monthName = date.toLocaleString('en-US', { month: 'long' }); // Obtener mes en inglés
      const translatedMonth = translateYearMonth(`${monthName} ${date.getFullYear()}`); // Traducir mes al español
      months.push({
        year: date.getFullYear(),
        month: monthName, // Mantener el mes en inglés para el agrupamiento
        yearMonth: `${monthName} ${date.getFullYear()}`, // Mantener en inglés para la clave
        translatedYearMonth: translatedMonth, // Campo adicional traducido
      });
      date.setMonth(date.getMonth() + 1);
    }

    // Asegurar que cada combinación mes-año esté presente en los datos
    const completeData = months.map(({ year, month, yearMonth, translatedYearMonth }) => {
      const found = groupedData.find(item => item.year === year && item.month === month);
      return found ? { ...found, yearMonth: translatedYearMonth } : { year, month, liters: 0, yearMonth: translatedYearMonth };
    });

    setData(completeData);
    setLoading(false);
  };

  // Manejo de clics en los elementos del menú para asegurar el comportamiento deseado
  const handleMenuItemClick = (event, option, type) => {
    event.stopPropagation();
    const selectedOption = option.value;

    if (selectedOption === 'all') {
      // Si se selecciona "Mostrar todo", deseleccionamos otras opciones
      setFilters(prevFilters => ({
        ...prevFilters,
        [type]: [],
        ['showAll' + capitalizeFirstLetter(type)]: true,
      }));
    } else {
      // Si se selecciona otra opción, deseleccionamos "Mostrar todo" y actualizamos las opciones seleccionadas
      setFilters(prevFilters => {
        const newSelectedOptions = prevFilters[type].includes(selectedOption)
          ? prevFilters[type].filter(item => item !== selectedOption)
          : [...prevFilters[type], selectedOption];

        const showAllKey = 'showAll' + capitalizeFirstLetter(type);
        return {
          ...prevFilters,
          [type]: newSelectedOptions,
          [showAllKey]: newSelectedOptions.length === 0, // Si no hay opciones seleccionadas, volvemos a "Mostrar todo"
        };
      });
    }
  };

  return (
    <Paper className={classes.container}>
      <Typography variant="h6" gutterBottom>
        Evolutivo anual
      </Typography>

      {loading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={classes.filterContainer}>
            {/* Filtro Naviera */}
            <FormControl variant="outlined" size="small" className={classes.filterItem}>
              <InputLabel>Naviera</InputLabel>
              <Select
                multiple
                value={filters.showAllNaviera ? ['all'] : filters.naviera}
                input={<OutlinedInput label="Naviera" />}
                renderValue={(selected) => {
                  if (filters.showAllNaviera) {
                    return 'Mostrar todo';
                  }
                  return selected.join(', ');
                }}
                MenuProps={MenuProps}
              >
                <MenuItem value="all" onClick={(e) => handleMenuItemClick(e, { value: 'all' }, 'naviera')}>
                  <Checkbox checked={filters.showAllNaviera} />
                  <ListItemText primary="Mostrar todo" />
                </MenuItem>
                {navieras.map((naviera, index) => (
                  <MenuItem key={index} value={naviera} onClick={(e) => handleMenuItemClick(e, { value: naviera }, 'naviera')}>
                    <Checkbox checked={filters.naviera.includes(naviera)} />
                    <ListItemText primary={naviera} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Filtro Embarcación */}
            <FormControl variant="outlined" size="small" className={classes.filterItem}>
              <InputLabel>Embarcación</InputLabel>
              <Select
                multiple
                value={filters.showAllEmbarcacion ? ['all'] : filters.embarcacion}
                input={<OutlinedInput label="Embarcación" />}
                renderValue={(selected) => {
                  if (filters.showAllEmbarcacion) {
                    return 'Mostrar todo';
                  }
                  return selected.join(', ');
                }}
                MenuProps={MenuProps}
              >
                <MenuItem value="all" onClick={(e) => handleMenuItemClick(e, { value: 'all' }, 'embarcacion')}>
                  <Checkbox checked={filters.showAllEmbarcacion} />
                  <ListItemText primary="Mostrar todo" />
                </MenuItem>
                {embarcaciones.map((embarcacion, index) => (
                  <MenuItem key={index} value={embarcacion} onClick={(e) => handleMenuItemClick(e, { value: embarcacion }, 'embarcacion')}>
                    <Checkbox checked={filters.embarcacion.includes(embarcacion)} />
                    <ListItemText primary={embarcacion} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Filtro Cliente */}
            <FormControl variant="outlined" size="small" className={classes.filterItem}>
              <InputLabel>Cliente</InputLabel>
              <Select
                multiple
                value={filters.showAllCliente ? ['all'] : filters.cliente}
                input={<OutlinedInput label="Cliente" />}
                renderValue={(selected) => {
                  if (filters.showAllCliente) {
                    return 'Mostrar todo';
                  }
                  return selected.join(', ');
                }}
                MenuProps={MenuProps}
              >
                <MenuItem value="all" onClick={(e) => handleMenuItemClick(e, { value: 'all' }, 'cliente')}>
                  <Checkbox checked={filters.showAllCliente} />
                  <ListItemText primary="Mostrar todo" />
                </MenuItem>
                {clientes.map((cliente, index) => (
                  <MenuItem key={index} value={cliente} onClick={(e) => handleMenuItemClick(e, { value: cliente }, 'cliente')}>
                    <Checkbox checked={filters.cliente.includes(cliente)} />
                    <ListItemText primary={cliente} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={data}
              margin={{
                top: 20, right: 30, left: 20, bottom: 80, // Aumentamos el margen inferior para etiquetas rotadas
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="yearMonth" 
                tickFormatter={(yearMonth) => translateYearMonth(yearMonth)}
                angle={-45}
                textAnchor="end"
                interval={0}
                height={60} // Aumentamos la altura para acomodar las etiquetas rotadas
              />
              <YAxis />
              <Tooltip 
                labelFormatter={(label) => translateYearMonth(label)}
              />
              <Bar dataKey="liters" name="Litros" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </>
      )}
    </Paper>
  );
};

export default MonthlyFuelDeliveries;
