import jwtAuthAxios from "./auth/jwtAuth";

const invitationServices = {};

invitationServices.inviteUser = async (params) => {
    const {data} = await jwtAuthAxios.post(`/invitation`, { invite: params });
    return data;
};

invitationServices.getInvitationInfo = async (token) => {
    const {data} = await jwtAuthAxios.get(`/invitations/info?token=${token ?? ""}`);
    return data;
}

invitationServices.confirmInvitation = async (params) => {
    const {data} = await jwtAuthAxios.post(`/invitations/confirm`, { invitation: params });
    return data;
}

export default invitationServices;