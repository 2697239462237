import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, TextField, Radio, InputAdornment, Grid, FormLabel, FormGroup, FormControlLabel, Checkbox, CircularProgress } from "@mui/material";
import _ from "lodash";
import Div from "@jumbo/shared/Div";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { LoadingButton } from '@mui/lab';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PrettyPatternInput from 'app/widgets/Shared/PrettyPatternInput';
import Swal from 'sweetalert2';
import { defaultSwalPreferences } from 'app/utils/appHelpers';
import invitationServices from 'app/services/invitation-services';

const UserInviteModal = props => {
    const {isOpen, onClose=()=>{}, onSave=()=>{}} = props;
    const [saving, setSaving] = useState(false)
    const [inviteEmail, setInviteEmail] = useState('')

    const inviteUser = () => {
        setSaving(true)
        invitationServices.inviteUser({ email: inviteEmail, include: 'companies' })
            .then(resp => {
                console.log(resp)
                Swal.mixin(defaultSwalPreferences(Swal)).fire({
                    icon: 'success',
                    title: `Invitación enviada a ${inviteEmail}`,
                    background: 'success',
                });
                onClose()
                setSaving(false)
            })
            .catch(function (error) {
                setSaving(false)
            });
    }

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={isOpen}
                onClose={onClose}>
                <DialogTitle>
                    <Typography variant={"h2"} color="primary">Invitar usuario</Typography>
                </DialogTitle>
                <DialogContent>
                    { saving ?
                        <Div sx={{ textAlign: 'center' }}>
                            <CircularProgress />
                        </Div>
                    :
                        <React.Fragment>
                            <Typography sx={{ mb: 2 }}>Ingresa el correo electrónico de la persona que quieres invitar:</Typography>
                            <TextField
                                fullWidth
                                label="Correo electrónico"
                                // error={!currentUser?.email}
                                value={inviteEmail || ''}
                                onChange={(e) => setInviteEmail(e.target.value)}
                            />
                        </React.Fragment>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { onClose() } }>Cerrar</Button>
                    <LoadingButton
                        loading={saving}
                        variant="contained"
                        color="success"
                        size="small"
                        endIcon={<SaveOutlinedIcon/>}
                        loadingPosition="end"
                        onClick={inviteUser}>
                            { saving ? 'Guardando' : 'Guardar' }
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default UserInviteModal;