import React, { useEffect, useState } from 'react';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import {SortableElement, SortableHandle} from 'react-sortable-hoc';
import {Alert, Autocomplete, Avatar, Button, Chip, CircularProgress, Divider, FormControlLabel, IconButton, ListItem, ListItemAvatar, ListItemText, Stack, Switch, TableCell, TableRow, TextField, Tooltip} from "@mui/material";
import {Autocomplete as MapsAutocomplete, useLoadScript} from "@react-google-maps/api";
import Typography from "@mui/material/Typography";
import StateBadge from 'app/widgets/Shared/StateBadge';
import moment from 'moment';
import { GOOGLE_MAPS_API_KEY } from 'app/utils/constants/paths';
import _, { add, debounce } from 'lodash';
import orderServices from 'app/services/order-services';
import { encodeParams } from 'app/utils/appHelpers';
import tripServices from 'app/services/trip-services';
import { LoadingButton } from '@mui/lab';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import customerServices from 'app/services/customer-services';
import placeServices from 'app/services/place-services';
import { id } from 'date-fns/locale';
import { useContext } from "react";
import { AuthContext } from "@jumbo/components/JumboAuthProvider/JumboAuthContext"

const ActionButtons = ({opts=[]}) => {
    return (
        <Stack direction="row" spacing={1}>
            { _.map(opts, (opt, idx) => {
                return (
                    <LoadingButton key={`abs_${idx}`} {...opt} size={opt.size || 'small'} color={opt.color || 'primary'} variant={opt.variant || 'contained'} >
                        {opt.label}
                    </LoadingButton>
                )
            })}
        </Stack>
    )
}

const DeliveryCell = ({tripId, delivery, canSort, stop, formNew=false, onSelect=()=> {}, onAdd=()=> {}, onRemove=()=> {}}) => {
    const [addMethod, setAddMethod] = useState(null); // address and name / customer / order
    const { authUser } = useContext(AuthContext);

    const [newOrder, setNewOrder] = useState(null);
    const [newCustomer, setNewCustomer] = useState(null);
    const [comment, setComment] = useState("");

    const [newPlace, setNewPlace] = useState(null);
    const [placeName, setPlaceName] = useState("");
    const [placeAddress, setPlaceAddress] = useState("");
    const [searchResult, setSearchResult] = useState(null);

    const [addingStop, setAddingStop] = useState(false);
    const [removingStopId, setRemovingStopId] = useState(null);

    const [ordersOptsLoading, setOrdersOptsLoading] = useState(false);
    const [customersOptsLoading, setCustomersOptsLoading] = useState(false);
    const [placesOptsLoading, setPlacesOptsLoading] = useState(false);

    const [ordersOpts, setOrdersOpts] = useState([]);
    const [customersOpts, setCustomersOpts] = useState([]);
    const [placesOpts, setPlacesOpts] = useState([]);

    const getOrdersOptions = async (search) => {
        setOrdersOptsLoading(true)
        var params = encodeParams(_.join([
            "include=locatable",
            "q[state_not_in][]=cancelled",
            "q[state_not_in][]=finished",
            `q[code_or_locatable_of_Customer_type_name_or_locatable_of_Place_type_name_cont]=${search}`
        ], '&'))
        orderServices.getOrders(`?${params}`)
            .then(data => {
                setOrdersOpts(data.data)
            })
            .finally((err) => {
                setOrdersOptsLoading(false)
            });
    }

    const getCustomersOptions = async (search) => {
        setCustomersOptsLoading(true)
        var params = encodeParams(_.join([
            "include=location",
            `q[name_cont]=${search}`
        ], '&'))
        customerServices.getCustomers(`?${params}`)
            .then(data => {
                setCustomersOpts(data.data)
            })
            .finally((err) => {
                setCustomersOptsLoading(false)
            });
    }

    const getPlacesOptions = async () => {
        if(placeName.length > 2) {
            setPlacesOptsLoading(true)
            var params = encodeParams(_.join([
                "include=location",
                `q[name_cont]=${placeName}`
            ], '&'))
            placeServices.getPlaces(`?${params}`)
                .then(data => {
                    setPlacesOpts(data.data)
                })
                .finally((err) => {
                    setPlacesOptsLoading(false)
                });
        }
    }

    const addByOrder = async () => {
        if(!newOrder) { return }
        setAddingStop(true)
        tripServices.addByOrder(tripId, {order_id: newOrder.id, comment: comment})
            .then(data => {
                setAddingStop(false)
                onAdd(data)
            })
            .catch((err) => {
                setAddingStop(false)
            });
    }

    const addByCustomer = async () => {
        if(!newCustomer) { return }
        setAddingStop(true)
        tripServices.addByCustomer(tripId, {customer_id: newCustomer.id, comment: comment})
            .then(data => {
                setAddingStop(false)
                onAdd(data)
            })
            .catch((err) => {
                setAddingStop(false)
            });
    }

    const addByPlace = async () => {
        if(!(newPlace?.id || (placeName && placeAddress))) { return }
        setAddingStop(true)
        tripServices.addByPlace(tripId, { place_id: newPlace?.id, name: placeName, address: placeAddress, comment: comment})
            .then(data => {
                setAddingStop(false)
                onAdd(data)
            })
            .catch((err) => {
                setAddingStop(false)
            });
    }

    const removeDelivery = async () => {
        setRemovingStopId(delivery.id)
        tripServices.removeDelivery(tripId, { delivery_id: delivery.id })
            .then(data => {
                setRemovingStopId(null)
                onRemove(delivery.id)
            })
            .catch((err) => {
                setRemovingStopId(null)
                setAddingStop(false)
            });
    }

    function onLoad(autocomplete) {
        setSearchResult(autocomplete);
    }

    function onPlaceChanged() {
        if (searchResult != null) {
          const newAddr  = searchResult.getPlace().formatted_address;

          if(newAddr) {
            setPlaceAddress(newAddr)
          }
        }
    }

    const debouncedGetPlacesOptions = _.debounce(getPlacesOptions, 500)

    useEffect(() => {
        debouncedGetPlacesOptions()
    }, [placeName])
    

    const renderForm = () => {
        if (authUser?.current_company?.id === 80) {
            return null;
        }
        switch (addMethod) {
        case null:
            return (
                <Stack spacing={1}>
                    <Typography variant={"body1"}>Agregar parada en</Typography>
                    <ActionButtons opts={[
                        {label: "Pedido", variant: "outlined", endIcon: <AddOutlinedIcon />, onClick: () => setAddMethod('order')},
                        {label: "Cliente", variant: "outlined", endIcon: <AddOutlinedIcon />, onClick: () => setAddMethod('customer')},
                        {label: "Otro lugar", variant: "outlined", endIcon: <AddOutlinedIcon />, onClick: () => setAddMethod('address')}
                    ]}/>
                </Stack>
            )
        case 'order':
            return (
                <Stack sx={{width: 400}} spacing={1}>
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                        <Typography variant={"body1"}>Agregar</Typography>
                        <Typography color="primary" variant={"body1"}>pedido</Typography>
                    </Stack>
                    <Stack spacing={1}>
                        <Autocomplete
                            id="new-order-select"
                            size="small"
                            freeSolo
                            fullWidth
                            openOnFocus
                            autoComplete
                            noOptionsText="No hay resultados"
                            onInputChange={_.debounce((e, v) => { if(v) { getOrdersOptions(v) } }, 500)}
                            options={ordersOpts}
                            getOptionLabel={(option) => `${option.code} - ${option.locatable?.name}`}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props}>
                                        <Stack direction="row" fullWidth justifyContent="space-between" spacing={0.5}>
                                            <Stack direction="row" spacing={0.5}>
                                                <Typography variant="body1">{option.code}</Typography>
                                                <Typography variant="body2">{option.locatable?.name}</Typography>
                                            </Stack>
                                            <StateBadge state={option.state} stateName={option.state_name} withIcon={false}/>
                                        </Stack>
                                    </li>
                                )
                            }}
                            value={newOrder}
                            onChange={(e, v) => { if(v) { setNewOrder(v) } }}
                            renderInput={(params) => 
                                <TextField {...params}
                                    label="Nº pedido"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {ordersOptsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }} />
                            }
                        />
                        <TextField
                            fullWidth
                            size="small"
                            label="Comentario"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </Stack>
                    <FormControlLabel label="Asignar todos los productos" sx={{m: 0}} disabled control={<Switch defaultChecked size="small" />} />
                    <ActionButtons opts={[
                        {label: "Cancelar", color: "error", disabled: addingStop, endIcon: <CloseOutlinedIcon />, onClick: () => setAddMethod(null)},
                        {label: "Agregar", color: "success", loading: addingStop, disabled: addingStop, endIcon: <AddCircleOutlineOutlinedIcon />, onClick: () => addByOrder()}
                    ]}/>
                </Stack>
            )
        case 'customer':
            return (
                <Stack sx={{width: 400}} spacing={1}>
                    <Stack direction="row" spacing={0.5}>
                        <Typography variant={"body1"}>Agregar parada en</Typography>
                        <Typography color="primary" variant={"body1"}>cliente</Typography>
                    </Stack>
                    <Autocomplete
                        id="new-customer-select"
                        size="small"
                        freeSolo
                        fullWidth
                        openOnFocus
                        autoComplete
                        noOptionsText="No hay resultados"
                        onInputChange={_.debounce((e, v) => { if(v) { getCustomersOptions(v) } }, 500)}
                        options={customersOpts}
                        getOptionLabel={(option) => `${option.code} - ${option.name}`}
                        renderOption={(props, option) => {
                            return (
                                <li {...props}>
                                    <Stack spacing={0.5}>
                                        <Stack direction="row" spacing={0.5}>
                                            <Typography variant="body1">{option.code}</Typography>
                                            <Typography variant="body2">{option.name}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={0.5}>
                                            <Typography variant="body2" sx={{ color: "text.secondary" }}>{option.location?.short_address} | {option.location?.short_zone}</Typography>
                                        </Stack>
                                    </Stack>
                                </li>
                            )
                        }}
                        value={newCustomer}
                        onChange={(e, v) => { if(v) { setNewCustomer(v) } }}
                        renderInput={(params) => 
                            <TextField {...params}
                                label="Cliente"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {customersOptsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }} />
                        }
                    />
                    <TextField
                        fullWidth
                        size="small"
                        label="Comentario"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                    <ActionButtons opts={[
                        {label: "Cancelar", color: "error", disabled: addingStop, endIcon: <CloseOutlinedIcon />, onClick: () => setAddMethod(null)},
                        {label: "Guardar", color: "success", loading: addingStop, disabled: addingStop, endIcon: <AddCircleOutlineOutlinedIcon />, onClick: () => addByCustomer()}
                    ]}/>
                </Stack>
            )
        case 'address':
            return (
                <Stack sx={{width: 400}} spacing={1}>
                    <Stack direction="row" spacing={0.5}>
                        <Typography variant={"body1"}>Agregar parada en</Typography>
                        <Typography color="primary" variant={"body1"}>otro lugar</Typography>
                    </Stack>
                    { newPlace ?
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Stack>
                                <Typography variant="body2">{newPlace.name}</Typography>
                                <Typography variant="body2" sx={{ color: "text.secondary" }}>{newPlace.location?.short_address}</Typography>
                            </Stack>
                            <ActionButtons opts={[
                                {label: "Cambiar", color: "primary", endIcon: <ChangeCircleOutlinedIcon />, onClick: () => setNewPlace(null)}
                            ]}/>
                        </Stack>
                    :
                        <Stack direction="row" alignItems="center">
                            <Autocomplete
                                id="new-place-select"
                                size="small"
                                freeSolo
                                openOnFocus
                                autoComplete
                                noOptionsText="No hay resultados"
                                onInputChange={(e,v) => { setPlaceName(v) }}
                                options={placesOpts}
                                getOptionLabel={(option) => `${option.name}`}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props}>
                                            <Stack spacing={0.5}>
                                                <Stack direction="row" spacing={0.5}>
                                                    <Typography variant="body1">{option.code}</Typography>
                                                    <Typography variant="body2">{option.name}</Typography>
                                                </Stack>
                                                <Stack direction="row" spacing={0.5}>
                                                    <Typography variant="body2" sx={{ color: "text.secondary" }}>{option.location?.short_address} | {option.location?.short_zone}</Typography>
                                                </Stack>
                                            </Stack>
                                        </li>
                                    )
                                }}
                                value={newPlace}
                                onChange={(e, v) => { if(v) { setNewPlace(v) } }}
                                renderInput={(params) => 
                                    <TextField {...params}
                                        label="Lugar"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {placesOptsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                        sx={{ width: 200 }}/>
                                }
                            />
                            <MapsAutocomplete
                                onPlaceChanged={onPlaceChanged}
                                onLoad={onLoad}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Ubicación"
                                        disabled={newPlace?.id}
                                        sx={{ width: 200 }}/>
                            </MapsAutocomplete>
                        </Stack>
                    }
                    <TextField
                        fullWidth
                        size="small"
                        label="Comentario"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                    <ActionButtons opts={[
                        {label: "Cancelar", color: "error", disabled: addingStop, icon: <CloseOutlinedIcon />, onClick: () => setAddMethod(null)},
                        {label: "Guardar", color: "success", loading: addingStop, disabled: addingStop, icon: <AddCircleOutlineOutlinedIcon />, onClick: () => addByPlace()}
                    ]}/>
                </Stack>
            )
        }
    }

    if(formNew) {
        return (
            <ListItem
                id='newDelivery'
                key='newDelivery'
                alignItems="flex-start">
                
                <ListItemAvatar sx={{ mt: 0 }}></ListItemAvatar>

                <ListItemText id={`lit_newDelivery`}
                    primary={renderForm()}
                />
            </ListItem>
        )
    } else {
        const {code, state, state_name, locatable, planned_start, planned_end} = delivery;
        return (
            <ListItem
                id={delivery.id}
                key={delivery.id}
                // alignItems="flex-start"
                onClick={() => onSelect(delivery)}
                sx={{
                    zIndex: 100000,
                    cursor: canSort ? 'grab' : 'auto',
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    "&:hover": {
                        backgroundColor: "#f5f5f5"
                    },
                }}
                secondaryAction={
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                        <Chip
                            size="small"
                            label={ `${planned_end ? moment(planned_end).format('DD MMM HH:mm') : '-'}`}
                            icon={<AccessTimeOutlinedIcon fontSize="small"/>}
                        />
                        <StateBadge state={state} stateName={state_name} sx={{width: 100}}/>
                        {!_.includes(['cancelled', 'finished'], delivery.state) && 
                            <IconButton
                                sx={{"&:hover": { color: theme => theme.palette.error.main }}}
                                disabled={removingStopId === delivery.id}
                                onClick={() => { removeDelivery() }} >
                                {removingStopId === delivery.id ?
                                    <CircularProgress size={20} />
                                    :
                                    <DeleteOutlineOutlinedIcon />
                                }
                            </IconButton>
                        }
                    </Stack>
                }>
                
                <ListItemAvatar>
                    <Avatar>
                        {stop}
                    </Avatar>
                </ListItemAvatar>
    
                <ListItemText id={`lit_${delivery.id}`}
                    primary={
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={1}>
                            <Stack sx={{width: '50%'}}>
                                <Typography variant={"body1"} mb={0}>{tripId} - {code} {delivery?.order?.code && `(P: ${delivery?.order?.code})`}</Typography>
                                <Typography variant={"body1"} mb={0}>{locatable?.code} {locatable?.name}</Typography>
                            </Stack>
                        </Stack>
                    }
                    secondary={
                        <React.Fragment>
                            <Typography>{locatable?.location?.short_address} | {locatable?.location?.short_zone}</Typography>
                            { delivery.comment &&
                                <Tooltip title="Comentario de despacho">
                                    <Alert severity="warning" icon={<CommentOutlinedIcon fontSize="small" />} sx={{['&.MuiAlert-root']: { display: 'inline-flex', px: 0.5, py: 0, fontSize: 11 } }}>
                                        {delivery.comment}
                                    </Alert>
                                </Tooltip>
                            }
                        </React.Fragment>
                    }
    
                />
            </ListItem>
        );
    }
};

export default SortableElement(DeliveryCell);