import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardMedia, CircularProgress, TextField, Typography, alpha } from "@mui/material";
import _ from "lodash";
import { useParams } from 'react-router-dom';
import Logo from 'app/shared/Logo';
import Div from '@jumbo/shared/Div';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import invitationServices from 'app/services/invitation-services';
import { defaultSwalPreferences } from 'app/utils/appHelpers';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';


const ConfirmInvitation = () => {
    const navigate = useNavigate();
    const { token } = useParams();
    const [confirmData, setConfirmData] = useState({
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        password_confirmation: '',
    })
    const [info, setInfo] = useState(null);
    const [infoLoading, setInfoLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState(null);
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        setInfoLoading(true)
        invitationServices.getInvitationInfo(token)
            .then(resp => {
                console.log(resp)
                setInfo(resp)
                setConfirmData({
                    ...confirmData,
                    email: resp?.email,
                    first_name: resp?.first_name,
                    last_name: resp?.last_name,
                })
            })
            .catch((resp) => {
                Swal.mixin(defaultSwalPreferences(Swal)).fire({
                    icon: 'error',
                    title: resp.data?.message ?? "La invitación ha expirado o no es válida.",
                    background: 'error',
                });
            })
            .finally(() => setInfoLoading(false))
    }, [token]);

    const onAcceptInvitation = (data, {setSubmitting}) => {
        invitationServices.confirmInvitation({
            token,
            ...data
        })
        .then(() => {
            setSubmitting(false);
            Swal.mixin(defaultSwalPreferences(Swal)).fire({
                icon: 'success',
                title: `¡Bienvenido ${confirmData.first_name}!`,
                text: 'Tu cuenta ha sido creada exitosamente, inicia sesión para continuar.',
                background: 'success',
                showConfirmButton: false,
                timer: 10000,
            });
            navigate("/auth/login")
        })
        .catch(() => {
            setSubmitting(false);
        });
    }

    const validateFields = (continueCall) => {
        let errors = yup.object().shape({
            email: yup.string().email('Email inválido').required('Debes completar este campo'),
            first_name: yup.string().required('Debes completar este campo').nullable(),
            last_name: yup.string().required('Debes completar este campo').nullable(),
            password: yup.string().required('Debes completar este campo').nullable(),
            password_confirmation: yup.string().required('Debes completar este campo').oneOf([yup.ref('password')], 'Las contraseñas no coinciden'),
        }).validate(confirmData, {abortEarly: false})
        .then(() => {
            setErrors(null);
            if(continueCall) {
                setSubmitting(true);
                onAcceptInvitation(confirmData, {setSubmitting});
            }
        })
        .catch((err) => {
            let error = {};
            err.inner.forEach(e => {
                console.log(e)
                console.log(e.path)

                error[e.path] = e.message;
            });
            setErrors(error);
            if(continueCall) {
                setShowErrors(true);
            }
        });
    }
    
    return (
        <Div sx={{
            flex: 1,
            flexWrap: 'wrap',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: theme => theme.spacing(4),
        }}>
            <Logo sx={{mb: 3}} />
            <Card sx={{maxWidth: '100%', width: 360, mb: 4}}>
                <Div sx={{position: 'relative', height: '200px'}}>
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="200"
                        image={`${ASSET_IMAGES}/banner-1.jpg`}
                    />
                    <Div
                        sx={{
                            flex: 1,
                            inset: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: theme => alpha(theme.palette.common.black, .5),
                            p: theme => theme.spacing(3),
                        }}
                    >
                        <Typography
                            variant={"h2"}
                            sx={{
                                color: 'common.white',
                                fontSize: '1.5rem',
                                mb: 0
                            }}
                        >
                            Confirma tu invitación
                        </Typography>
                    </Div>
                </Div>
                <CardContent sx={{pt: 0}}>
                    { infoLoading ? (
                            <Div sx={{textAlign: 'center', my: 5}}>
                                <CircularProgress color="warning"/>
                            </Div>
                        ) : (
                            <React.Fragment>
                                <Typography my={2}>
                                    { (info?.invited_by?.first_name && info?.company_name) && <React.Fragment><b>{info?.invited_by?.first_name} {info?.invited_by?.last_name}</b> te ha invitado a <b>{info?.company_name}</b>. </React.Fragment>}Ingresa tus datos y acepta la invitación para continuar.
                                </Typography>
                                <TextField
                                    fullWidth
                                    label="Correo electrónico"
                                    value={confirmData?.email || ''}
                                    error={showErrors && !!errors?.email}
                                    helperText={showErrors && errors?.email}
                                    sx={{mb: 1}}
                                    disabled={true}
                                    onChange={(e) => setConfirmData({...confirmData, email: e.target.value})}
                                    onBlur={(e) => { validateFields(false) }}
                                />
                                <TextField
                                    fullWidth
                                    label="Nombre"
                                    value={confirmData?.first_name || ''}
                                    error={showErrors && !!errors?.first_name}
                                    helperText={showErrors && errors?.first_name}
                                    sx={{mb: 1}}
                                    onChange={(e) => setConfirmData({...confirmData, first_name: e.target.value})}
                                    onBlur={(e) => { validateFields(false) }}
                                />
                                <TextField
                                    fullWidth
                                    label="Apellido"
                                    value={confirmData?.last_name || ''}
                                    error={showErrors && !!errors?.last_name}
                                    helperText={showErrors && errors?.last_name}
                                    sx={{mb: 1}}
                                    onChange={(e) => setConfirmData({...confirmData, last_name: e.target.value})}
                                    onBlur={(e) => { validateFields(false) }}
                                />
                                <TextField
                                    fullWidth
                                    label="Contraseña"
                                    type="password"
                                    value={confirmData?.password || ''}
                                    error={showErrors && !!errors?.password}
                                    helperText={showErrors && errors?.password}
                                    sx={{mt: 1, mb: 1}}
                                    onChange={(e) => setConfirmData({...confirmData, password: e.target.value})}
                                    onBlur={(e) => { validateFields(false) }}
                                />
                                <TextField
                                    fullWidth
                                    label="Confirmar Contraseña"
                                    type="password"
                                    value={confirmData?.password_confirmation || ''}
                                    error={showErrors && !!errors?.password_confirmation}
                                    helperText={showErrors && errors?.password_confirmation}
                                    sx={{mb: 1}}
                                    onChange={(e) => setConfirmData({...confirmData, password_confirmation: e.target.value})}
                                    onBlur={(e) => { validateFields(false) }}
                                />
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{my: 2}}
                                    loading={submitting}
                                    // disabled={}
                                    onClick={validateFields}
                                >Aceptar invitación</LoadingButton>
                            </React.Fragment>
                        )
                    }
                </CardContent>
            </Card>
        </Div>
    );
};

export default ConfirmInvitation;