import moment from "moment";
import {USE_IMAGE_PLACEHOLDERS} from "./constants/paths";
import _ from "lodash";
import LocalShippingTwoToneIcon from '@mui/icons-material/LocalShippingTwoTone';
import DirectionsBoatTwoToneIcon from '@mui/icons-material/DirectionsBoatTwoTone';

export const getAssetPath = (url, size) => {
    if(USE_IMAGE_PLACEHOLDERS) {
        return `https://via.placeholder.com/${size}.png`;
    }

    return url;
};

export const defaultSwalPreferences = (swalObj) => {
    return {
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: toast => {
            toast.addEventListener('mouseenter', swalObj.stopTimer);
            toast.addEventListener('mouseleave', swalObj.resumeTimer);
        }
    }
}

export const geoCoords = strCoords => {
    var out = _.map(_.compact(_.split(strCoords, ";")), (p) => { return {lat: parseFloat(_.split(p, ',')[0]), lng: parseFloat(_.split(p, ',')[1]) } })
    return out
}

export const getAvatarInitials = (str) => {
    let initials = str.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
}

export const optimizerNames = (slug) => {
    switch(slug) {
        case 'route-optimizer-by-delivery-zones':
            return 'Zonas de despacho';
        default:
            return 'Desconocido';
    }
}

export const encodeParams = (strparams) => {
    // const params = {
    //     include: "customer",
    //     "q[code_or_customer_name_cont]": "hola"
    // };
    var out = ""
    _.map(_.split(strparams, '&'), (p) => {
        var [k, v] = _.split(p, '=')
        if(out.length > 0) {
            out = `${out}&`
        }
        out = `${out}${encodeURIComponent(k)}=${encodeURIComponent(v)}`
    })
    return out
}

export const downloadBuffer = (buffer, fileName) => {
    const blob = new Blob(
      [ buffer ],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
    )
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); // Establece el nombre del archivo aquí.
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export const prettyDuration = (_start, _end) => {
    const start_ = moment(_start)
    const end_ = moment(_end)
    const diff = moment.duration(end_.diff(start_));
    var horas = diff.hours();
    var minutos = diff.minutes();
    var segundos = diff.seconds();
    var output = []
    if(horas > 0) { output.push(`${horas}h`) }
    output.push(`${minutos}m`)
    output.push(`${segundos}s`)

    return output.join(" ")
    // return moment.utc(end.diff(start).asMilliseconds()).format("HH:mm:ss.SSS")
}

export const hasPlugin = (pluginList, plugin) => {
    if(pluginList && _.find(pluginList, { slug: plugin })) {
        return true;
    }
    return false;
}

export const vehicleIcon = (vh) => {
    var tus = [vh?.transport_unit1?.kind, vh?.transport_unit2?.kind]
    if(_.includes(tus, 'barge')) {
        return <DirectionsBoatTwoToneIcon fontSize="small" color={"primary"} />
    } else if(_.includes(tus, 'truck')) {
        return <LocalShippingTwoToneIcon fontSize="small" color={"primary"} />
    } else {
        return <LocalShippingTwoToneIcon fontSize="small" color={"primary"} />
    }
}