import React, { useEffect, useState } from 'react';
import dashboardServices from '../../services/dashboard-services';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '10px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    margin: '20px 0',
    padding: '20px',
    position: 'relative',
  },
  table: {
    minWidth: 650,
    borderRadius: '10px',
  },
  headerCell: {
    fontWeight: 'bold',
    color: '#4B5563',
    fontSize: '16px',
    backgroundColor: '#F3F4F6',
    borderBottom: '2px solid #E5E7EB',
  },
  bodyCell: {
    color: '#1F2937',
    fontSize: '14px',
    borderBottom: '1px solid #E5E7EB',
  },
  row: {
    '&:hover': {
      backgroundColor: '#F9FAFB',
    },
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 1,
  },
}));

const StockByShip = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dashboardServices.getStockByShip()
      .then(response => {
        // Mapea la data para que el componente tenga los datos listos
        const mappedData = response.map(unit => {
          // Obtener la última orden según la fecha de actualización
          const lastOrder = unit.orders.length 
            ? unit.orders.reduce((latest, order) => {
                const latestOrderDetail = latest.order_details[0].updated_at;
                const currentOrderDetail = order.order_details[0].updated_at;
                return new Date(currentOrderDetail) > new Date(latestOrderDetail) ? order : latest;
              }) 
            : null;
          
          // Obtener el stock y la última recepción
          const stock = lastOrder ? lastOrder.order_details[0].pending_qty : '';
          const lastReception = lastOrder ? new Date(lastOrder.created_at).toLocaleDateString('es-ES') : '';
        
          return {
            ppu: unit.ppu,
            capacity: unit.max_load_volume,
            stock,
            lastReception
          };
        });        

        setData(mappedData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Typography variant="h6" gutterBottom>
        Stock por barco
      </Typography>
      {loading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>#</TableCell> {/* Nueva Celda para Índice */}
              <TableCell className={classes.headerCell}>Embarcación</TableCell>
              <TableCell align="right" className={classes.headerCell}>Stock Actual</TableCell>
              <TableCell align="right" className={classes.headerCell}>Capacidad (L)</TableCell>
              <TableCell align="right" className={classes.headerCell}>Fecha Última Recepción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={row.ppu || index} className={classes.row}> {/* Mejorar la clave */}
                <TableCell component="th" scope="row" className={classes.bodyCell}>
                  {index + 1} {/* Mostrar el Índice */}
                </TableCell>
                <TableCell component="th" scope="row" className={classes.bodyCell}>
                  {row.ppu}
                </TableCell>
                <TableCell align="right" className={classes.bodyCell}>{row.stock}</TableCell>
                <TableCell align="right" className={classes.bodyCell}>{row.capacity.toLocaleString()}</TableCell>
                <TableCell align="right" className={classes.bodyCell}>{row.lastReception}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default StockByShip;
