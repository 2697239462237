import jwtAuthAxios from "./auth/jwtAuth";

const dashboardServices = {};

dashboardServices.getStockByShip = async () => {
    try {
      const { data } = await jwtAuthAxios.get('/dashboard/get_stock_by_ship');
      return data;
    } catch (error) {
      console.error('Error fetching stock by ship data:', error);
      throw error;
    }
};

dashboardServices.getDeliveriesByDate = async (filters) => {
  try {
      const { naviera, embarcacion, cliente, desde, hasta } = filters;

      const queryParams = new URLSearchParams({
          naviera: naviera || '',
          embarcacion: embarcacion || '',
          cliente: cliente || '',
          desde: desde || '',
          hasta: hasta || ''
      }).toString();

      const { data } = await jwtAuthAxios.get(`/dashboard/get_deliveries_by_date?${queryParams}`);
      return data;
  } catch (error) {
      console.error('Error fetching deliveries by date:', error);
      throw error;
  }
};

dashboardServices.getDeliveriesByCustomer = async (filters) => {
  try {
    const { cliente, naviera, embarcacion, desde, hasta } = filters;

    // Construir la URL con los filtros aplicados
    const params = {};

    if (cliente && cliente.length > 0) params.cliente = cliente.join(',');
    if (naviera && naviera.length > 0) params.naviera = naviera.join(',');
    if (embarcacion && embarcacion.length > 0) params.embarcacion = embarcacion.join(',');
    if (desde) params.desde = desde;
    if (hasta) params.hasta = hasta;

    const queryParams = new URLSearchParams(params).toString();

    const { data } = await jwtAuthAxios.get(`/dashboard/get_deliveries_by_customer?${queryParams}`);
    return data;
  } catch (error) {
    console.error('Error fetching deliveries by customer:', error);
    throw error;
  }
};

dashboardServices.getMonthlyFuelDeliveries = async (filters) => {
  try {
    const params = {};
    if (filters.naviera && filters.naviera.length > 0) params.naviera = filters.naviera.join(',');
    if (filters.embarcacion && filters.embarcacion.length > 0) params.embarcacion = filters.embarcacion.join(',');
    if (filters.cliente && filters.cliente.length > 0) params.cliente = filters.cliente.join(',');

    const queryParams = new URLSearchParams(params).toString();

    const { data } = await jwtAuthAxios.get(`/dashboard/get_monthly_fuel_deliveries?${queryParams}`);
    return data;
  } catch (error) {
    console.error('Error fetching monthly fuel deliveries:', error);
    throw error;
  }
};


  
export default dashboardServices;
