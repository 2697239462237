import React, { useEffect, useState } from 'react';
import {Alert, Autocomplete, Box, IconButton, LinearProgress, List, ListItem, ListItemText, Stack, TextField, Tooltip, Typography, styled} from "@mui/material";
import _ from "lodash";
import Div from "@jumbo/shared/Div";
import { Draggable, Droppable } from "react-beautiful-dnd";
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import JumboScrollbar from '@jumbo/components/JumboScrollbar';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import moment from 'moment';
import PlaceIcon from '@mui/icons-material/Place';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { LoadingButton } from '@mui/lab';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { StyledDatePicker } from 'app/widgets/Shared/StyledDatePicker/StyledDatePicker';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import { useCompanySettings } from 'app/providers/CompanySettingsProvider/CompanySettingsProvider';
import { Search, SearchIconWrapper, StyledInputBase } from 'app/shared/SearchGlobal/style';
import SearchIcon from "@mui/icons-material/Search";

const DNDList = styled(List)(({theme, isDraggingOver}) => ({
    padding: theme.spacing(1),
    background: isDraggingOver ? "#E0F4FF" : "inherit",
}));

const OrdersDND = props => {
    const { companySettings } = useCompanySettings();
    const {
        title,
        items,
        listLoading,
        droppableId,
        newGroupContainer,
        groupNumber,
        groupColor,
        showSearch=false,
        leftActions,
        rightActions,
        isPlanification=false,
        selShipDate=null,
        selDriver=null,
        selVehicle=null,
        drivers=[],
        vehicles=[],
        selectedBoxes=[],
        onToggleSelection = () => {},
        onToggleSelectionInGroup = () => {},
        onItemMouseOver = () => {},
        onItemMouseLeave = () => {},
        onShipDateChange = () => {},
        onSelDriverChange = () => {},
        onSelVehicleChange = () => {},
        onEndPlanification = () => {}
    } = props;
    const [saving, setSaving] = useState(false);
    const [query, setQuery] = useState('');

    const isSelected = (idx) => {
        return _.find(selectedBoxes, { index: idx });
    };

    const boxStyle = (isDragging, isSelected) => {
        if (isDragging || isSelected) {
            return {
                background: '#F3EEEA',
                boxShadow:  '2px 2px 10px 0px rgba(0,0,0,0.3)',
                borderColor: '#888888',
            }
        } else {
            return {
                borderColor: '#DDDDDD',
            }
        }
    }
    
    // Determines if the platform specific toggle selection in group key was used
    const wasToggleInSelectionGroupKeyUsed = (event) => {
        const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
        return isUsingWindows ? event.ctrlKey : event.metaKey;
    };

    // Determines if the multiSelect key was used
    const wasMultiSelectKeyUsed = (event) => event.shiftKey;

    const onItemTouchEnd = (e, droppableId, idx) => {
        if (e.defaultPrevented) {
            return;
        }
        // marking the event as used
        // we would also need to add some extra logic to prevent the click
        // if this element was an anchor
        e.preventDefault();
        onToggleSelectionInGroup(droppableId, idx);
    }

    const onItemClick = (e, droppableId, idx) => {
        if (e.defaultPrevented) {
          return;
        }
    
        if (e.button !== 0) {
          return;
        }
    
        // marking the event as used
        e.preventDefault();

        if (wasToggleInSelectionGroupKeyUsed(e)) {
            onToggleSelectionInGroup(droppableId, idx);
            return;
        }
    
        // if (wasMultiSelectKeyUsed(event)) {
        //     multiSelectTo(task.id);
        //     return;
        // }
    
        onToggleSelection(droppableId, idx);
    };

    const filteredItems = () => {
        return _.filter(items, (item) => {
            const attrs = [
                item.code,
                item.locatable?.name,
                item.locatable?.location?.short_zone,
                item.comment
            ];
            return _.some(attrs, (attr) => { return _.includes(attr?.toLowerCase(), query.toLowerCase()) });
        });
    }

    const handleSearchQueryChange = (newVal) => {
        setQuery(newVal);
    }
    const debounceSearchQueryChange = _.debounce(handleSearchQueryChange, 100)

    return (
        <JumboCardQuick
            title={
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant={"h5"} mb={0}>{title}{groupNumber && ` ${groupNumber}` }</Typography>
                        {leftActions}
                    </Stack>
                    {showSearch &&
                        <TextField
                            id="input-with-sx"
                            label="Buscar"
                            size="small"
                            onChange={(e) => debounceSearchQueryChange(e.target.value)}
                            variant="outlined" />
                    }
                    {rightActions}
                </Stack>
            }
            headerSx={{ px: 1 }}
            noWrapper
            sx={{mb: 2}}>
            { items &&
                <JumboScrollbar
                    autoHeight                    
                    autoHeightMax={700}>
                    <Div sx={{width: '100%', display: listLoading ? 'block' : 'none'}}>
                        <LinearProgress  />
                    </Div>
                    {filteredItems().length === 0 &&
                        <Typography variant="body2" color="textSecondary" align="center" mt={2}>No hay datos</Typography>
                    }
                    {isPlanification &&
                        <Stack direction="row" alignItems="center" spacing={1} sx={{px:1, mt:1}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Fecha"
                                    format="dd/MM"
                                    freeSolo
                                    blurOnSelect
                                    sx={{ background: 'white', width: '30%' }}
                                    value={selShipDate}
                                    // minDate={new Date()}
                                    closeOnSelect={false}
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                        },
                                        actionBar: {
                                            actions: ['clear', 'accept'],
                                        },
                                    }}
                                    onAccept={(newValue) => onShipDateChange(newValue)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <Autocomplete
                                id="centre-select"
                                size="small"
                                freeSolo
                                blurOnSelect
                                sx={{ width: '35%' }}
                                options={drivers}
                                getOptionLabel={(option) => `${option.code ? option.code : "S/C"} - ${option.first_name}${option.last_name ? ` ${option.last_name}` : ''}`}
                                value={selDriver}
                                onChange={(e, v) => { onSelDriverChange(e, v); }}
                                renderInput={(params) => <TextField {...params} label="Conductor" sx={{background: 'white'}} />}
                            />
                            <Autocomplete
                                id="centre-select"
                                size="small"
                                freeSolo
                                blurOnSelect
                                sx={{ width: '35%' }}
                                options={vehicles}
                                getOptionLabel={(option) => `${option.code ? option.code : "S/C"} - ${option.transport_unit1?.ppu}${option.transport_unit2 ? `/${option.transport_unit2.ppu}` : ''}`}
                                value={selVehicle}
                                onChange={(e, v) => { onSelVehicleChange(e, v); }}
                                renderInput={(params) => <TextField {...params} label="Vehículo" sx={{background: 'white'}} />}
                            />
                            <Tooltip title="Finalizar planificación" sx={{ justifyContent: 'center' }}>
                                <IconButton
                                    value="check"
                                    variant="contained"
                                    disabled={saving || (companySettings?.plan_require_driver && !selDriver) || (companySettings?.plan_require_vehicle && !selVehicle)}
                                    color="success"
                                    size="small"
                                    onClick={(e) => { onEndPlanification(e); }}>
                                    <SendOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    }
                    <Droppable droppableId={droppableId}>
                        {(provided, snapshot) => (
                            <DNDList disablePadding {...provided.droppableProps} ref={provided.innerRef} isDraggingOver={snapshot.isDraggingOver}>
                                {/* { (!items || items.length === 0) &&
                                    <ListItem
                                    dense={true}
                                    key={`empty`}
                                    ref={provided.innerRef}>
                                        <ListItemText
                                            secondary={
                                                <Stack direction="row" justifyContent="space-between">
                                                    <Typography>Sin datos</Typography>
                                                </Stack>
                                            }
                                        />
                                    </ListItem>
                                } */}
                                {_.map(items, (order,i) => {
                                        return (
                                            <Draggable key={`pendingOrder_${order.id}`} draggableId={`pendingOrder_${order.id}`} index={i}>
                                                {(provided, snapshot) => {
                                                    const isHidden = !_.includes(filteredItems(), order);
                                                    return (
                                                        <ListItem
                                                            key={`ol_${order.id}`}
                                                            dense={true}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            isDragging={snapshot.isDragging}
                                                            onMouseEnter={(e) => onItemMouseOver(e, order)}
                                                            onMouseLeave={(e) => onItemMouseLeave(e, order)}
                                                            onClick={(e) => onItemClick(e, droppableId, i)}
                                                            onTouchEnd={(e) => onItemTouchEnd(e, droppableId, i)}
                                                            sx={{
                                                                border: 1,
                                                                marginBottom: .5,
                                                                display: isHidden ? 'none' : 'block',
                                                                ...boxStyle(snapshot.isDragging, isSelected(i)),
                                                                '&:hover': {
                                                                    background: '#F3F3F3',
                                                                    cursor: 'pointer',
                                                                    boxShadow: '2px 2px 10px 0px rgba(0,0,0,0.3)',
                                                                    borderColor: '#888888',
                                                                }
                                                            }}>
                                                            <ListItemText
                                                                primary={
                                                                    <Stack direction="row" alignItems="start" spacing={.5} justifyContent='space-between'>
                                                                        <Typography
                                                                            variant='h5'
                                                                            mb={.5}
                                                                            sx={{ display: 'inline'}}
                                                                            component="span">
                                                                                <PlaceIcon color={ order.locatable?.has_location ? 'success' : 'error' } sx={{ fontSize: 14 }} />
                                                                                {order.code} - {order.locatable?.name}
                                                                        </Typography>
                                                                        <Typography variant="body2" color={"text.secondary"} noWrap sx={{width: 85}} align='right'>{_.sumBy(order.order_details, 'pending_qty')} items</Typography>
                                                                    </Stack>
                                                                }
                                                                secondary={
                                                                    <React.Fragment>
                                                                        <Stack direction="row" justifyContent="space-between">
                                                                            <Typography>{order.locatable?.location?.short_zone || '-'}</Typography>
                                                                            <Stack direction="row" alignItems="center" spacing={.5}>
                                                                                <Typography variant="body2" color={"text.secondary"} ml={1}>{moment(order.ship_date).utc().format('DD MMM')}</Typography>
                                                                                <CalendarMonthTwoToneIcon fontSize="small" color={"primary"} />
                                                                            </Stack>
                                                                        </Stack>
                                                                        {order.comment &&
                                                                            <Alert severity="info" icon={<CommentOutlinedIcon sx={{ fontSize: 16 }} />} sx={{mt: 1, ['&.MuiAlert-root']: { px: 0.5, py: 0, fontSize: 11 } }}>
                                                                                {order.comment}
                                                                            </Alert>
                                                                        }
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </ListItem>
                                                    )
                                                }}
                                            </Draggable>
                                        )
                                    })
                                }
                                {provided.placeholder}
                            </DNDList>
                        )}
                    </Droppable>
                </JumboScrollbar>
            }
            {newGroupContainer === true &&
                <Droppable droppableId={droppableId}>
                    {(provided, snapshot) => (
                        <DNDList disablePadding {...provided.droppableProps} ref={provided.innerRef} isDraggingOver={snapshot.isDraggingOver}>
                            <ListItem
                                dense={true}
                                key={`newtrip_1`}
                                ref={provided.innerRef}>
                                <ListItemText
                                    secondary={
                                        <Stack direction="row" justifyContent="space-between">
                                            <Typography>Arrastre órdenes aquí para crear una nueva ruta</Typography>
                                        </Stack>
                                    }
                                />
                            </ListItem>
                        </DNDList>
                    )}
                </Droppable>
            }
        </JumboCardQuick>
    )
}

export default OrdersDND;