import React, { useEffect, useState } from 'react';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import {Typography, Stack, List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Button, Rating, AvatarGroup, Avatar, Grid, Tooltip} from "@mui/material";
import Div from "@jumbo/shared/Div";
import _ from 'lodash';
import deliveryServices from 'app/services/delivery-services';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import ImageViewer from '../../widgets/Images/ImageViewer';
import SignatureCanvas from '../../widgets/Shared/SignatureCanvas/SignatureCanvas';

const formatTin = (tin) => {
    if (!tin) return '';
    
    const tinWithoutHyphen = tin.slice(0, -1);
    const formattedTin = tinWithoutHyphen.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    const lastChar = tin.slice(-1);
    
    return `${formattedTin}-${lastChar}`;
  };

const BoolToCheck = (props) => {
    if(props.value === true) {
        return (<CheckCircleRoundedIcon fontSize="small" color='success'/>)
    } else {
        return (<CancelRoundedIcon fontSize="small" color='error'/>)
    }
}

const DeliveryDetails = props => {
    const {authUser} = useJumboAuth();
    const [details, setDetails] = useState(null)
    const [detailsLoading, setDetailsLoading] = useState(false)
    const [openImageViewer, setOpenImageViewer] = useState(false)
    const [currentDetail, setCurrentDetail] = useState([])
    
    useEffect(() => {
        if (authUser) {
            // console.log(authUser.current_company);
        } else {
            console.log('authUser is not defined yet.');
        }
    }, [authUser]);



    useEffect(() => {
        if(props.delivery) {
            setDetailsLoading(true);
            deliveryServices.getDeliveryDetails(props.delivery?.id, "?include=order_detail,images_attachments")
                .then((data) => {
                    setDetails(data)
                    setDetailsLoading(false);
                });
        }
    }, [])

    const renderDetails = () => {
        if(details) {
            return(
                <List
                    dense={true}
                    sx={{ padding: 0 }}>
                    {_.map(details, (d) => {
                        return (
                            <ListItem
                                key={d.id}
                                id={d.id}
                                sx={{alignItems: 'flex-start'}}>
                                    <ListItemText id={d.id}
                                        primary={
                                            <Div>    
                                                <Stack direction="row" spacing={2}>
                                                    <Tooltip title={d.product_code} placement="left-start">
                                                        <Typography noWrap sx={{ fontFamily: 'Monospace', minWidth: 80, maxWidth: 80, fontSize: 12 }}>{d.product_code}</Typography>
                                                    </Tooltip>
                                                    <Stack direction="column" spacing={0}>
                                                        <Typography>{d.product}</Typography>
                                                        { d.observations ? 
                                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                                <Typography color="primary" sx={{ fontWeight: 'bold' }}>Obs</Typography>
                                                                <Typography>{d.observations}</Typography>
                                                            </Stack>
                                                            : null
                                                        }
                                                        { renderImagePreviews(d) }
                                                    </Stack>
                                                </Stack>
                                            </Div>
                                        }/>
                                   <Stack direction="row" justifyContent="space-between">
                                        {d.reception_msg ? <WarningAmberRoundedIcon color={d.reception_msg.kind} /> : null}
                                        <Typography sx={{ minWidth: 15, textAlign: 'right' }}>{d.delivered_qty?.toLocaleString()}</Typography>
                                        <Typography sx={{ minWidth: 12, textAlign: 'right' }}>/</Typography>
                                        <Typography sx={{ minWidth: 18, textAlign: 'right' }}>{d.qty?.toLocaleString()}</Typography>
                                        <Typography sx={{ minWidth: 25, textAlign: 'right' }}>{d.meassure_unit}</Typography>
                                    </Stack>
                            </ListItem>
                        )
                    })}
                </List>
            )
        }
    }

    const renderImagePreviews = (_detail) => {
        if(_detail.images) {
            return (
                <AvatarGroup max={3} sx={{justifyContent: 'flex-end', cursor: 'pointer'}} onClick={() => { setOpenImageViewer(true); setCurrentDetail(_detail) } }>
                    { _.map(_detail.images, (im) => {
                        return (<Avatar src={im} />)
                    })}
                </AvatarGroup>
            )
        }
    }

    const renderGeneralDescription = () => {
        if(props.delivery) {
            return(
                <Div sx={{ mb: 3 }}>
                <Typography variant="h4">Descripción general</Typography>
                <Stack sx={{ px: 2 }}>
                {authUser?.current_company?.id !== 80 ? (
                    <Stack direction="row">
                        <Typography sx={{ minWidth: 150, fontWeight: 'medium' }}>Nº Pedido:</Typography>
                        <Typography>{props.delivery.order?.code}</Typography>
                    </Stack>
                    ) : (
                    <Stack direction="row">
                        <Typography sx={{ minWidth: 150, fontWeight: 'medium' }}>Nº OC:</Typography>
                        <Typography>{props.delivery?.receipt?.observations}</Typography>
                    </Stack>
                    )}            
                {_.map(props.delivery.delivery_docs, (dd, index) => (
                    <Stack direction="row" key={index}>
                        <Typography sx={{ minWidth: 150, fontWeight: 'medium' }}>
                        {dd.shipping_doc?.shipping_doc_kind?.slug === 'delivery-receipt' ? 'Nº Guía:' : 'Nº Factura:'}
                        </Typography>
                        <Typography>{dd.shipping_doc?.customer_code}</Typography>
                    </Stack>
                    ))}
                </Stack>
                </Div>
            )
        }
    }

    const renderReceptorDetails = () => {
        if(props.delivery?.receipt) {
            const formattedTin = formatTin(props.delivery?.receipt?.tin);
            return(
                <Div sx={{mb: 3}}>
                    <Typography variant={"h4"}>Recibido por</Typography>
                    <Stack sx={{px: 2}}>
                        <Stack direction="row"><Typography sx={{minWidth: 150, fontWeight: 'medium' }}>Nombre:</Typography>{props.delivery?.receipt?.name}</Stack>
                        <Stack direction="row"><Typography sx={{minWidth: 150, fontWeight: 'medium' }}>RUT:</Typography>{formattedTin}</Stack>
                        {authUser?.current_company?.id !== 80 && 
                        <Stack direction="row"><Typography sx={{minWidth: 150, fontWeight: 'medium' }}>Observaciones:</Typography>{props.delivery?.receipt?.observations}</Stack>
                        }
                    </Stack>
                </Div>
            )
        }
    }

    const renderSurveyAnswers = () => {
        if(props.delivery?.survey_answers && props.delivery?.survey_answers.length > 0) {
            return(
                <Div sx={{mb: 3}}>
                    <Typography variant={"h4"}>Resultados encuesta</Typography>
                    {_.map(props.delivery?.survey_answers, (ans) => {
                        return (
                            <Stack sx={{px: 2, mb: 2}}>
                                <Stack spacing={0.5}>
                                    <Typography sx={{minWidth: 150, fontWeight: 'medium' }}>{ans.survey_question.title}</Typography>
                                    <Stack direction="row" alignItems="bottom" spacing={0.5}>
                                        {ans.survey_question.kind === 'binary' ? <BoolToCheck value={ans.answer}/> : null}
                                        {ans.survey_question.kind === 'calification' ? <Rating name="read-only" value={ans.answer} readOnly size="small" /> : null}
                                        <Typography>
                                        {ans.survey_question.kind === 'binary' ?
                                            (ans.binary_value === true ? 'Si' : 'No')
                                        : ans.survey_question.kind === 'calification' ?
                                            `(${ans.answer})`
                                        : ans.answer
                                        }
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        )
                    })}
                </Div>
            )
        }
    }

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={props.isOpen}
                onClose={props.onClose}>
                <DialogTitle>
                    <Div sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1}}>
                        <Typography variant={"h2"} color="primary">Entrega Nº{props.delivery?.code}</Typography>
                    </Div>
                </DialogTitle>
                <DialogContent>
                    {detailsLoading ?
                        <Div sx={{textAlign: 'center', my: 5}}>
                            <CircularProgress color="warning"/>
                        </Div>
                    :
                        <React.Fragment>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={6}>
                                    {renderGeneralDescription()}
                                    {renderReceptorDetails()}
                                </Grid>
                                <Grid item xs={12} lg={6} sx={{textAlign: 'right'}}>
                                    {props.delivery?.receipt?.signature && <SignatureCanvas height={200} width={200} signatureData={JSON.parse(props.delivery.receipt.signature)} caption="Firma" />}
                                </Grid>
                            </Grid>
                            {authUser?.current_company?.id !== 80 && renderSurveyAnswers()}
                            <Typography variant={"h4"}>Detalle de productos</Typography>
                            {renderDetails()}
                        </React.Fragment>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { props.onClose() } }>Cerrar</Button>
                    {/* <LoadingButton
                        loading={saving}
                        variant="contained"
                        color={ canSave ? 'success' : 'primary' }
                        size='small'
                        endIcon={<SaveOutlinedIcon/>}
                        loadingPosition="end"
                        onClick={() => saveRoute()}>
                            { saving ? 'Guardando' : 'Guardar' }
                    </LoadingButton> */}
                </DialogActions>
            </Dialog>
            <ImageViewer
                isOpen={openImageViewer}
                onClose={() => setOpenImageViewer(false)}
                images={currentDetail?.images} />
        </React.Fragment>
    )
}

export default DeliveryDetails;
